<template>
  <div class="champs-type-event-setting">
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div v-else class="mt-2">
      <div class="header-user">
        <v-row>
          <v-col cols="6">
            <div
              v-if="messageCopyCle"
              class="color-crm font-sz-14 bold-700 font-text mt-2 ml-3"
            >
              {{ $i18n.locale == 'fr' ? messageCopyCle : 'API key copied' }}
            </div>
          </v-col>
          <v-col>
            <v-progress-circular
              class="float-right mt-1"
              v-if="getCustomFieldsLoading"
              indeterminate
              color="#5C2DD3"
            ></v-progress-circular
          ></v-col>
          <v-col>
            <v-text-field
              v-model="searchFilter"
              clearable
              :prepend-inner-icon="'mdi-magnify'"
              :label="$t('search')"
              outlined
              :placeholder="
                $i18n.locale === 'fr' ? 'Rechercher un champ' : 'Search a field'
              "
              :persistent-placeholder="true"
              dense
              hide-details
              color="#5C2DD3"
            ></v-text-field
          ></v-col>
        </v-row>
      </div>
      <div class="table-users mt-2">
        <v-data-table
          v-model="selectedTable"
          :headers="$i18n.locale == 'fr' ? computedFields : computedFieldsEn"
          :items="getcustomFieldsComputed"
          :items-per-page="getcustomFieldsComputed.length"
          :sort-by.sync="sortBy"
          class="table-users"
          hide-default-footer
          :calculate-widths="false"
          :fixed-header="true"
          hide-action
          :no-data-text="
            getCustomFieldsLoading
              ? $i18n.locale == 'fr'
                ? 'Chargement... Veuillez patienter'
                : 'Loading... Please wait'
              : $i18n.locale == 'fr'
              ? `Il n'y a aucune donnée à afficher dans ce tableau.`
              : 'There is no data to display in this table.'
          "
        >
          <!-- BODY TABLE -->
          <template v-slot:[`item.name`]="{ item }">
            <td class="custom-cell text-capitalize">
              <div>
                {{ item.name }}
              </div>
            </td>
          </template>
          <template v-slot:[`item.default`]="{ item }">
            <td class="custom-cell text-center">
              <font-awesome-icon
                icon="check"
                class="img-h-19 color-vert"
                v-if="item.default === 1"
              />
            </td>
          </template>
          <template v-slot:[`item.type_events`]="{ item }">
            <td class="custom-cell text-capitalize">
              <div v-if="item.type_events && item.type_events.length">
                <p
                  class="font-p mt-1"
                  v-for="type in item.type_events"
                  :key="type.id"
                >
                  <v-chip color="#5c2dd31a" text-color="#5c2dd3">
                    {{ type.designation }}
                  </v-chip>
                  <!-- <template
                    v-if="
                      index < item.type_events.length - 1 &&
                        item.type_events.length > 1
                    "
                    ><strong class="bold-700 font-sz-13">,</strong></template
                  > -->
                </p>
              </div>
            </td>
          </template>
          <template v-slot:[`item.key`]="{ item }">
            <td class="custom-cell">
              <div class="block-flex-align-space-between">
                <div class="font-text font-sz-12 ellipsis">
                  {{ item.key }}
                </div>
                <div
                  @click="copyToClipboard(item)"
                  class="cursor-pointer"
                  :title="
                    $i18n.locale == 'fr' ? 'Copier la clé' : 'API key copied'
                  "
                >
                  <font-awesome-icon
                    class="color-crm font-text img-h-19"
                    icon="copy"
                  />
                </div>
              </div>
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td class="custom-cell">
              <div class="actions">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="#5C2DD3" dark v-bind="attrs" v-on="on" icon>
                      <span class="font-sz-20 mb-2"> ...</span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="handleUpdateClick(item)">
                      <v-list-item-title>{{ $t('update') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="handleDeleteClick(item)"
                      v-if="item.default === 0"
                    >
                      <v-list-item-title>
                        {{ $t('deleted') }}</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="copyToClipboard(item)">
                      <v-list-item-title>
                        {{
                          $i18n.locale === 'fr'
                            ? 'Copier la clé API'
                            : 'Copy API key'
                        }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- UPDATE_CUSTOM_FIELDS -->
    <v-dialog v-model="Modalupdatechamps" max-width="900" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? "Modifier un champ pour le type d'événement"
                : 'Edit event type field'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('Modalupdatechamps')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="Modalupdatechamps" class="mt-2" v-if="champsToUpdate">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  v-model="champsToUpdate.name"
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                  disabled
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :label="
                    $i18n.locale === 'fr' ? 'Type du champ' : 'Field type'
                  "
                  dense
                  clearable
                  :placeholder="
                    $t('searchMsg', {
                      msg:
                        $i18n.locale === 'fr' ? 'Type du champ' : 'Field type'
                    })
                  "
                  v-model="champsToUpdate.field_type"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option:
                        $i18n.locale === 'fr' ? 'Type du champ' : 'Field type'
                    })
                  "
                  item-text="label"
                  item-value="id"
                  :items="getAllTypescustomFields"
                  outlined
                  return-object
                  :persistent-placeholder="true"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      $t('msgObligMsg', {
                        msg:
                          $i18n.locale === 'fr' ? 'Type du champ' : 'Field type'
                      })
                  ]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  disabled
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="visibilityOption(champsToUpdate.field_type)">
              <v-col>
                <v-text-field
                  label="Options"
                  dense
                  ref="refInputOption"
                  :disabled="getCustomFieldsLoading"
                  @click:append-outer="updateOptionsAdd(champsToUpdate)"
                  @click:clear="updateOptionsAdd(champsToUpdate)"
                  @keyup.enter="updateOptionsAdd(champsToUpdate)"
                  append-outer-icon="mdi-send"
                  :persistent-placeholder="true"
                  outlined
                  v-model="champsToUpdate.nameOption"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      (computedOptions &&
                        computedOptions.length &&
                        computedOptions[0].options &&
                        computedOptions[0].options.data &&
                        computedOptions[0].options.data.length > 0) ||
                      'Options est obligatoire'
                  ]"
                >
                </v-text-field>
                <Container
                  group-name="col"
                  drag-class="card-ghost"
                  drop-class="card-ghost-drop"
                  :drop-placeholder="dropPlaceholderOptions"
                  :lock-axis="'y'"
                  drag-handle-selector=".column-drag-container "
                  :class="'hello-002'"
                >
                  <Draggable v-for="item in computedOptions" :key="item.id">
                    <Container
                      group-name="col"
                      @drop="
                        onCardDropUpdateCustomFieldOptions(
                          $event,
                          item.options.data,
                          item.id
                        )
                      "
                      drag-class="card-ghost"
                      drop-class="card-ghost-drop"
                      :drop-placeholder="dropPlaceholderOptions"
                      :lock-axis="'y'"
                      drag-handle-selector=".column-drag-container "
                      :class="'hello-002'"
                    >
                      <Draggable
                        v-for="(option, index) in item.options.data"
                        :key="index"
                      >
                        <v-text-field
                          class="mt-2"
                          :label="'Option ' + `${index + 1}`"
                          dense
                          v-model="option.label"
                          :value="option.label"
                          :disabled="getCustomFieldsLoading"
                          @change="updateOptionsName(option, item.options.data)"
                          @click:append-outer="
                            updateOptionsDelete(option.id, item.options.data)
                          "
                          @click:clear="
                            updateOptionsDelete(option.id, item.options.data)
                          "
                          :append-outer-icon="
                            champsToUpdate &&
                            champsToUpdate.options &&
                            champsToUpdate.options.data &&
                            champsToUpdate.options.data.length > 1
                              ? 'mdi-trash-can-outline'
                              : ''
                          "
                          :persistent-placeholder="true"
                          outlined
                          color="#5C2DD3"
                          item-color="#5C2DD3"
                        >
                          <template #prepend>
                            <v-icon
                              class="cursor-move mr-2 ml-2"
                              :class="{
                                'column-drag-container':
                                  item.options.data &&
                                  item.options.data.length > 1
                              }"
                              >mdi-drag-horizontal-variant</v-icon
                            >
                          </template>
                        </v-text-field>
                      </Draggable>
                    </Container>
                  </Draggable>
                </Container>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <!-- TYPE EVENT -->
                <v-autocomplete
                  color="#5C2DD3"
                  multiple
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  :placeholder="
                    $t('searchMsg', {
                      msg:
                        $i18n.locale === 'fr' ? 'type événement' : 'event type'
                    })
                  "
                  item-color="#5C2DD3"
                  v-model="champsToUpdate.section"
                  :items="getTypeEvent"
                  :persistent-placeholder="true"
                  @input="clearTypeResource"
                  :label="
                    $i18n.locale === 'fr' ? 'Type événements' : 'event type'
                  "
                  item-text="designation"
                  dense
                  item-value="id"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option:
                        $i18n.locale === 'fr' ? 'type événement' : 'event type'
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-if="champsToUpdate.section && champsToUpdate.section.length"
            >
              <v-col>
                <div
                  v-for="(typeResource, index) in champsToUpdate.section"
                  :key="index"
                  class="block-fields"
                >
                  <v-row class="mb-1 mt-1">
                    <v-col class="col-ss-categ ml-1">
                      <div class="field-name">
                        {{
                          typeResource && typeResource.designation
                            ? typeResource.designation
                            : ''
                        }}
                      </div></v-col
                    >
                    <v-col class="col-ss-categ">
                      <v-switch
                        class="input-checkbox switch-bottom label-switch float-right"
                        :label="
                          $i18n.locale === 'fr'
                            ? 'Champ obligatoire'
                            : 'Required field'
                        "
                        color="#5C2DD3"
                        hide-details=""
                        v-model="typeResource.required"
                      >
                      </v-switch>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getCustomFieldsLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getCustomFieldsError" class="error-msg">
              <ul v-if="Array.isArray(getCustomFieldsError)">
                <li v-for="(e, index) in getCustomFieldsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getCustomFieldsError }}</span>
            </div>
          </div>
          <div v-if="error" class="error-msg">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmitUpdateCustomFields"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('Modalupdatechamps')">
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DELETE_CUSTOM_FIELDS -->
    <v-dialog v-model="ModalDelete" max-width="700" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? "Supprimer le champ du type d'événement"
                : 'Delete event type field'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalDelete')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p class="color-black-light">
            {{
              $t('msgdeleted', {
                msg:
                  $i18n.locale === 'fr'
                    ? 'le champ personnalisé :'
                    : 'the custom field :'
              })
            }}
            <strong class="text-capitalize">
              {{ champsToDelete ? champsToDelete.name : '' }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getCustomFieldsLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getCustomFieldsError" class="error-msg">
              <ul v-if="Array.isArray(getCustomFieldsError)">
                <li v-for="(e, index) in getCustomFieldsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getCustomFieldsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmitDeleteCustomFields"
            :loading="getCustomFieldsLoading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalDelete')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { Container, Draggable } from 'vue-smooth-dnd'
export default {
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      selectedTable: [],
      Modalupdatechamps: false,
      searchFilter: '',
      id_menu: null,
      customizable: 1,
      visibility: 1,
      initLoading: true,
      champsToDelete: null,
      champsToUpdate: null,
      selectedlink: 'type_events',
      selectedlinkButton: '',
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      fields: [
        { value: 'name', text: 'Nom', sortable: false },
        {
          value: 'type_events',
          text: 'Type événements',
          sortable: false
        },
        { value: 'key', text: 'Clé', sortable: false },
        {
          value: 'default',
          text: 'Champ systéme',
          tdClass: 'default-td-class',
          sortable: false
        },
        { value: 'actions', text: 'Actions', sortable: false }
      ],
      page: 1,
      sortBy: 'default',
      sortDesc: false,
      loading: false,
      loadingOpen: false,
      ModalDelete: false,
      messageCopyCle: null,
      error: null
    }
  },
  methods: {
    ...mapActions([
      'updateCustomFields',
      'deleteCustomFields',
      'fetchAllTablesCustomFields',
      'fetchAllCustomFieldsTypes',
      'fetchAllCustomFields',
      'applyDragCustomFields',
      'addCustomFieldsOptions',
      'deleteCustomFieldsOptions',
      'updateCustomFieldsOptions',
      'applyDragCustomFieldsOptions',
      'resetErrorCustomField',
      'fetchOneCustomFields',
      'fetchSubCategorieForCustemFields',
      'fetchAllTypeEvent'
    ]),
    clearTypeResource(chips) {
      this.champsToUpdate.section = chips
      this.$forceUpdate()
    },
    closeDialog(ref) {
      this[ref] = false
      this.error = null
      this.resetModal()
      if (ref == 'Modalupdatechamps') {
        this.$refs.Modalupdatechamps.resetValidation()
      }
    },
    resetModal() {
      this.resetErrorCustomField()
    },
    async changeCategorieInAddModal($event) {
      await this.fetchSubCategorieForCustemFields({
        event: $event
      })
    },
    handleDeleteClick(deletedType) {
      this.champsToDelete = { ...deletedType, menu: this.selectedlink }
      this.ModalDelete = true
    },
    async handleUpdateClick(updateChamp) {
      this.loadingOpen = true
      const objet = Object.assign({}, updateChamp)
      this.champsToUpdate = objet
      if (!this.getTypeEvent || !this.getTypeEvent.length) {
        this.fetchAllTypeEvent()
      }
      await this.fetchOneCustomFields(updateChamp)
      this.champsToUpdate.section = updateChamp.type_events
      this.champsToUpdate.field_type = this.champsToUpdate.fieldtype
      this.champsToUpdate.menu = this.selectedlink
      this.Modalupdatechamps = true
      this.loadingOpen = false
    },
    async updateOptionsAdd(click) {
      if (
        click &&
        ((!click.nameOption &&
          click.options &&
          (!click.options.data || !click.options.data.length)) ||
          !click.nameOption)
      ) {
        this.error = 'Le champ Options doit être rempli obligatoirement'
        return
      }
      const payload = {
        label: click.nameOption,
        field_id: click.id
      }
      this.error = null
      const response = await this.addCustomFieldsOptions(payload)
      if (response) {
        this.champsToUpdate.nameOption = null
        this.$refs.refInputOption.resetValidation()
      }
    },
    updateOptionsName(options) {
      this.error = null
      const payload = {
        label: options.label,
        order: options.order,
        id: options.id
      }
      if (
        this.champsToUpdate &&
        this.champsToUpdate.options &&
        this.champsToUpdate.options.data &&
        this.champsToUpdate.options.data.length == 1 &&
        options &&
        !options.label
      ) {
        this.error = 'Le champ Option 1 doit être rempli'
        return
      } else {
        this.updateCustomFieldsOptions(payload)
      }
    },
    updateOptionsDelete(deleteOption) {
      this.error = null
      if (
        this.champsToUpdate &&
        this.champsToUpdate.options &&
        this.champsToUpdate.options.data &&
        this.champsToUpdate.options.data.length > 1
      ) {
        this.deleteCustomFieldsOptions(deleteOption)
      }
    },
    addOptionsDelete(value) {
      let key = false
      for (
        let index = 0;
        index < this.champsToUpdate.selectedOption.length;
        index++
      ) {
        const option = this.champsToUpdate.selectedOption[index]
        if (option.id == value) {
          key = index
          break
        }
      }
      if (key !== false) {
        this.champsToUpdate.selectedOption.splice(key, 1)
      }
    },
    async handleSubmitUpdateCustomFields() {
      if (this.$refs.Modalupdatechamps.validate()) {
        let labelField = null
        this.getcustomFields.map(f => {
          if (f.id == this.champsToUpdate.id) {
            labelField = f.name
          }
        })
        let tabOptions = []

        if (
          this.champsToUpdate &&
          this.champsToUpdate.section &&
          this.champsToUpdate.section.length
        ) {
          for (
            let index = 0;
            index < this.champsToUpdate.section.length;
            index++
          ) {
            if (
              this.champsToUpdate.section[index] &&
              this.champsToUpdate.section[index].id
            ) {
              tabOptions.push({
                id: this.champsToUpdate.section[index].id,
                required: this.champsToUpdate.section[index].required ? 1 : 0
              })
            }
          }
        }
        this.error = null
        const payload = {
          name: this.champsToUpdate.name,
          id: this.champsToUpdate.id,
          label: labelField,
          type_events: tabOptions
        }
        const response = await this.updateCustomFields(payload)
        if (response) {
          this.closeDialog('Modalupdatechamps')
        }
      }
    },
    async handleSubmitDeleteCustomFields() {
      const response = await this.deleteCustomFields(this.champsToDelete.id)
      if (response) {
        this.closeDialog('ModalDelete')
      }
    },
    onCardDropAddCustomFieldOption(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      this.champsToUpdate.selectedOption = this.applyDrag(
        this.champsToUpdate.selectedOption,
        dropResult
      )
    },
    onCardDropUpdateCustomFieldOptions(dropResult, option, id_field) {
      this.error = null
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      const result = this.applyDrag(option, dropResult)
      this.updateCustomFieldsOptions({
        label: option[dropResult.removedIndex].label,
        order: dropResult.addedIndex,
        id: option[dropResult.removedIndex].id
      })

      this.applyDragCustomFieldsOptions({
        result,
        id_field
      })
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    copyToClipboard(text) {
      const el = document.createElement('textarea')
      el.value = text.key
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      el.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand('copy')
      document.body.removeChild(el)
      this.messageCopyCle = 'Clé API copié'
      setTimeout(() => {
        this.messageCopyCle = null
      }, 1500)
      //   this.$bvToast.toast(text.key, {
      //     title: ' Clé API copié',
      //     autoHideDelay: 2000,
      //     toaster: 'b-toaster-bottom-right',
      //     variant: 'success',
      //     solid: true
      //   })
    },
    removeDiacritics(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    normalizedContains(haystack, needle) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi')
      return regExp.test(this.removeDiacritics(haystack))
    }
  },
  computed: {
    ...mapGetters([
      'getcustomFields',
      'getAllTypescustomFields',
      'getCustomFieldsMenu',
      'getCustomFieldsLoading',
      'getCustomFieldsError',
      'getCustemFieldsSubCategorie',
      'getCustemFieldsSubCategorieList',
      'getTypeEvent'
    ]),
    visibilityOption() {
      return function(data) {
        if (
          data &&
          data.type &&
          (data.type == 'set' ||
            data.type == 'enum' ||
            data.type == 'one_select' ||
            data.type == 'checkbox')
        ) {
          return true
        } else {
          return false
        }
      }
    },
    computedOptions: function() {
      return this.getcustomFields.filter(o => {
        if (o.id == this.champsToUpdate.id) {
          return o.options.data
        }
      })
    },
    getcustomFieldsComputed: function() {
      const search = this.searchFilter
        ? this.searchFilter.trim()
        : this.searchFilter
      if (!search) {
        return this.getcustomFields
      }
      return this.getcustomFields.filter(c =>
        this.normalizedContains(c.name.toLowerCase(), search.toLowerCase())
      )
    },
    computedFields() {
      return this.fields
    },
    computedFieldsEn() {
      return [
        { value: 'name', text: 'Name', sortable: false },
        {
          value: 'type_events',
          text: 'Event type',
          sortable: false
        },
        { value: 'key', text: 'Key', sortable: false },
        {
          value: 'default',
          text: 'Field system',
          tdClass: 'default-td-class',
          sortable: false
        },
        { value: 'actions', text: 'Actions', sortable: false }
      ]
    }
  },
  async mounted() {
    if (!this.getCustomFieldsMenu || !this.getCustomFieldsMenu.length) {
      await this.fetchAllTablesCustomFields({
        customizable: this.customizable,
        visibility: this.visibility
      })
    }
    this.fetchAllCustomFieldsTypes()
    this.selectedlink = 'type_events'
    this.getCustomFieldsMenu.forEach(async c => {
      if (c && c.name == this.selectedlink) {
        this.id_menu = c.id
        await this.fetchAllCustomFields(this.id_menu)
      }
    })
    this.initLoading = false
  }
}
</script>

<style lang="scss">
.champs-type-event-setting {
  .table-users {
    table {
      border-collapse: collapse;
    }

    height: 100% !important;
    max-height: 100% !important;
    .header-users {
      th {
        text-align: left;
        padding: 0px 14px;
        height: 40px;
        font-size: 14px;
        white-space: nowrap;
        border-bottom: 1px solid #e0e0e0;
      }
    }
    thead {
      tr {
        th {
          white-space: nowrap;
          color: rgb(0 0 0) !important;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 12px !important;
          letter-spacing: 0.17px;
          line-height: 17.16px;
          font-family: 'Roboto';
        }
      }
    }
    .ellipsis {
      white-space: nowrap;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .v-data-table__wrapper::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    .v-data-table__wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .v-data-table__wrapper::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(189, 189, 189, 1);
    }
    .v-data-table__wrapper {
      max-height: calc(100vh - 324px) !important;
      height: calc(100vh - 324px) !important;
      margin-bottom: 0;
      overflow-y: auto;
      overflow-x: auto;
      cursor: pointer;
    }
  }
}
</style>
